import {
  Title,
  Text,
  Space,
} from '@mantine/core';

import {
  getPatientLabelFirstLine,
  getPatientLabelSecondLine,
  translatedPatientAgeAndSex,
} from '../utils/patient.utils';
import {
  isUserProvider,
} from '../utils/user.utils';
import { STYLE } from '../types/constants';
import BoxOrderView from './BoxOrderView';
import { UserType, ItemType } from '../types/enums';
import {
  isImplant,
  isOrderWithNoAttachement,
  isPAC,
} from "@jasper/shared";

const OrderViewPatientComponent = ({ order, user }) => {
  const dentistCode = `Dr${order.user?.firstName?.slice(0, 1)}${order.user
    ?.lastName}`;

  return (
    <BoxOrderView span={5} minHeight={28}>
      <Title
        order={5}
        style={{
          color: STYLE.primary,
        }}
      >
        Order {order.orderReference}
      </Title>
      <Space h="md" />
      <Title order={5}>Patient</Title>
      <Text>
        {getPatientLabelFirstLine(order)}
      </Text>
      <Text>
        {translatedPatientAgeAndSex(order?.patient)}
      </Text>
      <Space h="md" />
      <Title order={5}>Doctor</Title>
      <Text>
        {
          user.type !== UserType.SUPPLIER ? 
            `${order?.user?.firstName ?? "" } ${order?.user?.lastName ?? ""} - ${order?.user?.userGroup?.clinicNumber}`
            :   
              <>
                Doctor code : {dentistCode} <br/>
                Clinic code : {order.user.userGroup.clinicNumber} 
              </>
        } 
      </Text>
      <Space h="md" />
      {!isUserProvider(user) &&
        <div style={{ color: "purple" }}>
          {(order?.isRush ?? false) && "RUSH "}
          {(order?.isPhysicalPrint ?? false) && "PHYSICAL-PRINT "}
          {((order?.orderComment ?? []).length > 0) && " COMMENT "}
          {isImplant(order) && "IMPLANT "}
          {isOrderWithNoAttachement(order) && "NO-ATTACHEMENT "}
          {isPAC(order) && "PAC "}
          {((order?.redo ?? []).length > 0) && "REDO"}
        </div>
      }
    </BoxOrderView>
  );
};

export default OrderViewPatientComponent;
