import { useState, useEffect } from 'react';
import {
  Title,
  Space,
  Box,
  Button,
  Text,
  Modal,
  Grid,
  Flex,
} from '@mantine/core';
import { useLazyQuery } from "@apollo/client";
import JSZip from "jszip";

import BoxOrderView from './BoxOrderView';
import ItemsDescription from './itemDescription';
import TranslateCommentModal from './modals/translateCommentModal';
import { STYLE } from '../types/constants';
import { Product } from '../types/interfaces';
import {
  ItemType,
  FileType,
  OrderStatus,
  UserType,
} from '../types/enums';
import {
  isUserProvider,
  isUserStaff,
} from "../utils/user.utils";
import {
  get3OXZFile,
  getTraceabilitiesFile,
  getImagesFile,
  isUUID,
} from "../utils/file.utils";

import {
  GET_FILE_DOWNLOAD_URL,
} from "../gql/files";
import { useFiles } from '../utils/useFiles';
import { IconCloudUpload } from '@tabler/icons-react';
import { ButtonFileInput } from '../stories/ButtonFileInput/ButtonFileInput';
import { FileList } from '../stories/FileList/FileList';
import { Dropzone, FileWithPath } from '@mantine/dropzone';


const OrderViewOrderDetail = ({ order, selectedProduct, setSelectedProduct, user, refetch }) => {

  const [openTranslationModal, setOpenTranslationModal] = useState(false);
  const [commentToTranslate, setCommentToTranslate] = useState(null);
  const [allFiles, setAllFiles] = useState([]);
  const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);

  const [getFileDownloadUrl] = useLazyQuery(GET_FILE_DOWNLOAD_URL);
  const {uploadFileToS3} = useFiles(order.id);

  const createFileFromUrl = async(url: string) => {
    const req = await fetch(url);
    const data = await req.blob();
    const metadata = {
      type: "application/x-zip-compressed",
    };
    return (
      new File([data], "case.zip", metadata)
    );
  };

  const getStaffFiles = (files): {url: string, name: string, key?: string}[] => {
    const output = [];
    files
      .filter(file => file.fileType.toLowerCase() !== FileType.CHAT_FILE.toLowerCase())
      .forEach(async(file: { id: string, key: string, fileType: FileType }) => {
        const fileUrl = await getFileDownloadUrl({
          variables: {
            key: file.key,
          }
        });
        if (fileUrl){
          output.push({
            url: fileUrl.data.getPresignedFileDownloadUrl,
            name: file.fileType,
            key: file.key,
          });
        }
      });
    setAllFiles([...allFiles, ...output]);
    return (output);
  };

  const getDCM = async(allFiles): {url: string, name: string, key?: string}[] => {
    const orderFiles = allFiles.filter((file) => file.fileType.toLowerCase() === FileType.ORDER_FILE.toLowerCase().replace("_", ""));
    if (!orderFiles){
      return [];
    }
    const output = [];
    await Promise.all(orderFiles.map(async(element) => {
      const files = await getFileDownloadUrl({
        variables: {
          key: element.key,
        }
      });
      if(element.key.toLowerCase().includes('3oxz')){
        const zip = new JSZip();
        const zipfile = await createFileFromUrl(files?.data?.getPresignedFileDownloadUrl);
        const unzip = await zip.loadAsync(zipfile);
        await Promise.all((Object.keys(unzip.files)).map(async(zipname, index) => {
          const zipnamesplit = zipname.split(".");
          if (zipnamesplit[zipnamesplit.length - 1].toLowerCase() === "dcm"){
            const file = await (Object.values(unzip.files)[index]);
            const blogFile = await file.async("blob")
            const blob = new Blob([blogFile], { type: 'octet/stream' });
            output.push({
              url: window.URL.createObjectURL(blob),
              key: zipname
            });
          }
        }));
      }
    }))
    return (output);
  };

  const loadFiles = async() => {
    setIsFilesLoading(true);

    const staffFiles = await getStaffFiles(order?.files ?? []);
    const dcmFiles = await getDCM(order?.files ?? []);
    setAllFiles([...staffFiles, ...dcmFiles]);

    setIsFilesLoading(false);
  };

  useEffect(() => {
    loadFiles();
  }, [(order?.files ?? []).length]);

  return (
    <>
      <Modal
        opened={openTranslationModal}
        onClose={() => setOpenTranslationModal(false)}
      >
        <TranslateCommentModal
          order={order}
          closeModal={() => setOpenTranslationModal(false)}
          startProduction={() => startProduction()}
          refetch={refetch}
          comment={commentToTranslate}
        />
      </Modal>
      <BoxOrderView span={7} minHeight={54}>
        <Title
          order={4}
          style={{
            color: STYLE.primary,
          }}
        >
          {`Order detail${
            order?.products && order?.products.length > 1
              ? " (clic on a product to select it)"
              : ""
          }`}
        </Title>
        <Box>
          <Space h="md" />
          <Box>
            {order?.products &&
              (order.products ?? []).map((product: Product) => (
                <Box key={product.id}>
                  <Button
                    variant={
                      selectedProduct?.id === product.id
                        ? "filled"
                        : "outline"
                    }
                    styles={{inner: {justifyContent: "start"}}}
                    color={
                      selectedProduct?.id === product.id
                        ? STYLE.primary
                        : "black"
                    }
                    sx={theme => ({
                      "&:hover": {
                        backgroundColor:
                          selectedProduct?.id === product.id
                            ? undefined
                            : theme.colors.greyScale[9],
                      },
                    })}
                    h="auto"
                    p={8}
                    mb="xs"
                    w="100%"
                    onClick={() => setSelectedProduct(product)}
                  >
                    <ItemsDescription
                      key={product.id}
                      product={product}
                      shouldTranslate={true}
                    />
                  </Button>
                  {selectedProduct &&
                    selectedProduct.id === product.id &&
                    selectedProduct?.removableItem &&
                    selectedProduct.removableItem[0] &&
                    selectedProduct.removableItem[0].itemType ===
                      ItemType.PartialDenture && 
                    selectedProduct.removableItem[0].dentistNotes && (
                      <>
                        <Text size="sm">
                          {selectedProduct.removableItem[0]
                            .dentistNotes !== ""
                            ? `Commentaire sur les crochets: ${selectedProduct.removableItem[0].dentistNotes}`
                            : "Commentaire sur les crochets: Aucun"}
                        </Text>
                        <Space h="sm" />
                      </>
                    )}
                </Box>
              ))}
          </Box>
          <Space h="xl" />
          <Title order={5}>Doctor notes</Title>
          {isUserProvider(user) && (
            <>
              {(order?.orderComment ?? []).filter((orderComment) => orderComment.translatedComment).map((orderComment: { comment: string, translatedComment: string, type: string }, index: number) => (
                <Text style={{ marginLeft: "1rem" }}>
                  <li>{orderComment?.type} comment: {orderComment.translatedComment}</li>
                </Text>
              ))}
            </>
          )}
          {isUserStaff(user) && (
            <>
              {(order?.orderComment ?? []).map((orderComment: { id: string, comment: string, translatedComment: string, type: string }, index: number) => (
                <Text key={`order-comment-${orderComment.id}`} style={{ marginLeft: "1rem" }}>
                  <>Comment {index + 1} ({orderComment?.type})
                    &nbsp;{!orderComment.translatedComment &&
                      <span
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => {
                          setCommentToTranslate(orderComment);
                          setOpenTranslationModal(true);
                        }}
                      >
                        Translate
                      </span>
                    }
                  </>
                  <li><span>Comment:</span> {orderComment.comment}</li>
                  <li>
                    <span>Traduction:&nbsp;</span> 
                    {orderComment.translatedComment ?? "-"}
                    &nbsp;{orderComment.translatedComment && order.status === OrderStatus.CONFIRMED &&
                        <span
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                          onClick={() => {
                            setCommentToTranslate(orderComment);
                            setOpenTranslationModal(true);
                          }}
                        >
                          Update translation
                        </span>
                      }
                  </li>
                </Text>
              ))}
            </>
          )}
          {((order?.orderComment ?? []).length === 0) && (
            <Text>There is no notes on this order</Text>
          )}
        </Box>
        <Space h="xl" />
        <Title order={5}>Files</Title>
        <Grid gutter="sx">
          { isUserStaff(user) &&
            <>
              <Grid.Col key={'3OXZ'} span={6}>
                { get3OXZFile(allFiles).map((file) => {
                    return (
                      <a
                        key={file.id}
                        download={file.key ?? file.name}
                        href={file.url}
                      >
                        {file.name} (3OXZ)
                      </a>
                    );
                  })
                }
              </Grid.Col>
              <Grid.Col key={'traceability'} span={6}>
              { 
                getTraceabilitiesFile(allFiles).map((file) => 
                  <a
                    key={file.id}
                    download={file.key ?? file.name}
                    href={file.url}
                  >
                    {file.name} <br/>
                  </a>
                )
              }
              </Grid.Col>
              { [...getTraceabilitiesFile(allFiles), ...get3OXZFile(allFiles)].length !== 0 && 
                  <hr style={{
                    width: '100%',
                    borderBottom: '2px solid rgb(67, 98, 208)',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                  }} />
              }
            </>
          }
          <Grid.Col style={{marginTop:'15px'}} key={'pictures'} span={12}>
            {getImagesFile(allFiles).map((file) => {
              return (
                <a
                  key={file.id}
                  download={file.key ?? file.name}
                  href={file.url} 
                >
                  {((file?.key && isUUID(file?.key))?(file?.key)?.substring(37): file?.key) ?? file.name} <br/>
                </a>
              );
            })}
          </Grid.Col>
        </Grid>

        {(isFilesLoading) &&
          <Text>
            Loading files...
          </Text>
        }
        { user.type !== UserType.SUPPLIER && 
          <Dropzone 
            accept={{
              "image/*": [],
              "application/dicom": [".DCM", ".dcm"],
              "model/stl": [".stl",".STL"],
            }}
            onDrop={async (files) => {
              await uploadFileToS3([...files], FileType.LABS_FILE);
              refetch();
            }}
            style={{
              border: '2px dashed gray',
              borderRadius: '5px',
              padding: '5px',
              cursor: 'pointer'
            }}
          >
            <Flex
              justify="center"
              align="center"
            >
              <IconCloudUpload />
              <Box ml="sm">
                <Text
                  size="sm"
                  style={{fontWeight: 'bold'}}
                >
                  {" "}
                  {"Add a file to this order"}
                </Text>
              </Box>
            </Flex>
          </Dropzone>
        }
      </BoxOrderView>
    </>
  );
};

export default OrderViewOrderDetail;
