import {
  Title,
  Space,
  Text,
} from '@mantine/core';

import OrderStatusStepper from './orderStatusStepper';
import BoxOrderView from './BoxOrderView';
import {
  getOrderStatusStep,
  convertDateToReadableFormat,
} from '../utils/order.utils';
import {
  isUserProvider,
  isUserStaff,
} from "../utils/user.utils";
import { STYLE } from '../types/constants';
import { UserType, OrderStatus } from '../types/enums';

const OrderViewStepperComponent = ({ order, user }) => {
  const orderDate = order.history.find(
    history => history.status === OrderStatus.CONFIRMED
  )?.createdAt
    
  return (
    <BoxOrderView span={7} minHeight={28}>
      <Title
        order={5}
        style={{
          color: STYLE.primary,
        }}
      >
        Tracking
      </Title>
      <Space h="md" />
      <OrderStatusStepper
        orderStatus={getOrderStatusStep(
          order?.status,
        )}
        order={order}
        labelsUnder={true}
        grow={true}
      />
      <Space h="md" />
      {(order?.deliveryDate && (orderDate || order?.createdAt)) &&
        <>
          <Text
            style={{
              fontWeight: 'bold'
            }}
          >
            Ordered on:&nbsp;
            <span style={{ color: STYLE.primary }}>
              {
                convertDateToReadableFormat(orderDate ?? order.createdAt)
              }
            </span>
          </Text>
          <Text
            style={{
              fontWeight: 'bold'
            }}
          >
            Last shipping date from provider:&nbsp;<span style={{ color: STYLE.primary }}>{convertDateToReadableFormat(order.lastShippingDate)}</span>
          </Text>
          {!isUserProvider(user) &&
            <Text
              style={{
                fontWeight: 'bold'
              }}
            >
              Expected delivery on:&nbsp;<span style={{ color: STYLE.primary }}>{convertDateToReadableFormat(order.deliveryDate)}</span>
            </Text>
          }
          <Text
            style={{
              fontWeight: 'bold'
            }}
          >
            Status:&nbsp;
            <span style={{ color: STYLE.primary }}>
              {(order.status === OrderStatus.PRODUCING_IN_TRANSIT && isUserProvider(user)) ? "SHIPPED TO FRANCE" : order.status}{order?.isArchived ? " - Archived" : ""}
            </span> 
          </Text>
          {(isUserStaff(user) && order?.provider?.name || true) &&
            <Text
              style={{
                fontWeight: 'bold'
              }}
            >
              Provider:&nbsp;<span style={{ color: STYLE.primary }}>{order?.provider?.name}</span>
            </Text>
          }
        </>
      }
    </BoxOrderView>
  );
};

export default OrderViewStepperComponent;
