import React from 'react';
import { useForm } from "react-hook-form";
import {
  Button,
} from "@mantine/core";
import {
  useMutation,
} from "@apollo/client"
import {
  useLocation,
} from "react-router-dom";

import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import {
  STYLE,
} from "../../types/constants";
import {
  deduceShadeType,
} from '../../utils/item.utils';
import {
  ItemType,
} from "../../types/enums";


const CreateNewCrown = ({
  materialFormChoices,
  accountProductPreferences,
  order,
  anatomyItemChoicesData,
  product_id,
  onSubmit,
}) => {
  const location = useLocation();

  const savedItem = location?.state?.item;

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    defaultValues: {
      material: savedItem?.itemMaterial?.id,
      shade: savedItem?.itemShade?.id,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
    }
  });

  const watchShadeType = watch("shadeType");
  const watchMaterial = watch("material");
  const watchIsMultiShade = watch("isMultiShade");

  const submit = (data) => {
    onSubmit(data, watchIsMultiShade);
  };

  return (
    <>
      <SelectInputRef
        label="Matériau de la couronne"
        name="material"
        data={materialFormChoices}
        control={control}
        errors={errors}
        required={true}
      />
      {watchMaterial !== "METAL_NON_PRECIOUS" &&
        <ShadeSelection
          watchShadeType={watchShadeType}
          control={control}
          errors={errors}
          anatomyItemChoicesData={anatomyItemChoicesData}
          onSelect={(selectedValue) => setValue("shadeType", selectedValue)}
          order={order}
          accountProductPreferences={accountProductPreferences}
        />
      }
      <div style={{ textAlign: "center" }}>
        <Button style={{ backgroundColor: STYLE.primary }} onClick={handleSubmit(submit)}>
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateNewCrown;
