import {
  useState,
  useEffect,
} from "react";
import {
  useSearchParams,
  useParams
} from "react-router-dom";
import {
  useQuery,
  useMutation,
} from "@apollo/client";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import CreateNewInlayCoreComponent from './CreateNewInlayCoreComponent';
import CreateNewLay from './CreateNewLay';
import CreateNewCrown from './CreateNewCrown';

import {
  ItemType,
  TeethShadeType,
} from "../../types/enums";
import {
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
} from "../../gql/orders";
import {
  ANATOMY_ITEM_CHOICE_DATA,
  CREATE_ANATOMY_ITEM,
  UPDATE_ANATOMY_ITEM,
} from "../../gql/items";
import {
  GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES,
} from "../../gql/users";
import {
  getAnatomyItemMaterialChoices,
} from "../../utils/product.utils";

const NewItemComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { product_id, order_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const productType = searchParams.get("itemType");
  const itemId = searchParams.get("itemId");
  const savedItem = location?.state?.item;

  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);
  const [updateAnatomyItem] = useMutation(UPDATE_ANATOMY_ITEM);

  const { data: order, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id: order_id,
        }
      }
    }
  );
 
  const { data: anatomyItemChoicesData, loading: anatomyItemChoicesDataLoading } = useQuery(ANATOMY_ITEM_CHOICE_DATA);
  const { data: accountProductPreferences, loading: accountProductPreferencesLoading } = useQuery(GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES);

  const materialFormChoices = anatomyItemChoicesData
    ? getAnatomyItemMaterialChoices(anatomyItemChoicesData, productType)
    : []
  const product = (order?.getOrderByUniqueAttribute?.products ?? []).find((product) => product.id === product_id);
  
  switch(productType){
    case ItemType.INLAY_CORE:
      return (
        <CreateNewInlayCoreComponent
          productType={productType}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
          itemId={itemId}
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
        />
      );
    case ItemType.LAY:
    case ItemType.INLAY:
    case ItemType.ONLAY:
    case ItemType.OVERLAY:
      return (
        <CreateNewLay
          productType={productType}
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
        />
      );
    case ItemType.CROWN:
      return (
        <CreateNewCrown
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          onSubmit={async(data, watchIsMultiShade) => {
            if (savedItem?.id){
              if ((savedItem?.multiShadeInfo ?? []).length > 0){
                await updateAnatomyItem({
                  where: {
                    id: savedItem.id
                  },
                  data: {
                    multiShadeInfo: {
                      deleteMany: [
                        {
                          id: {
                            in: [(savedItem?.multiShadeInfo).map((multiShadeInfo) => multiShadeInfo.id)]
                          }
                        }
                      ]
                    }
                  }
                })
              }
              await updateAnatomyItem({
                variables: {
                  where: {
                    id: savedItem.id
                  },
                  data: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                    teeth: product.teeth,
                    teethshadeType: watchIsMultiShade
                      ? {
                        set: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade
                      } : undefined,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.Incisal,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  }
                }
              });
            } else {
              await createAnatomyItem({
                variables: {
                  args: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                    itemType: ItemType.CROWN,
                    teeth: product.teeth,
                    product: {
                      connect: {
                        id: product.id,
                      },
                    },
                    teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.Gingival,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.Base,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.Incisal,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  },
                },
              });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    default:
      return "undefined";
  }
};

export default NewItemComponent;
