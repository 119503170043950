import { useState } from 'react';
import {
  Space,
  Button,
  Text,
} from '@mantine/core';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';

import { STYLE } from '../../types/constants';
import {
  UPDATE_ITEM_BILLING,
} from '../../gql/itemBilling';
import {
  GET_ALL_MATERIAL_TRACEABILITY
} from '../../gql/materialTraceability';
import { ModelTypenames } from '../../types/enums';

import UpdateItemMaterialBrandAndLotNumberComponent from './UpdateItemMaterialBrandAndLotNumberComponent';

const UpdateMaterialAndLotModal = ({ order, closeModal, refetch, user }) => {
  
  const [isNewMaterialTraceability, setIsNewMaterialTraceability] = useState<boolean>(false);
 
  const [updateItemBilling] = useMutation(UPDATE_ITEM_BILLING);

  const { data: allMaterialTraceability, refetch: refetchMaterialTraceability } = useQuery(GET_ALL_MATERIAL_TRACEABILITY);

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      ...(order.products ?? []).reduce((acc, product) => {
        (product?.anatomyItem ?? []).forEach((item) => {
          acc[`${item.id}.lotNumber`] = item.itemBilling[0]?.firstMaterialLotNumber ?? ""
          acc[`${item.id}.materialBrand`] = item.itemBilling[0]?.firstMaterialTraceabilityId ?? ""
        });
        return (acc);
      }, {}),
      ...(order.products ?? []).reduce((acc, product) => {
        (product?.removableItem ?? []).forEach((item) => {
          acc[`${item.id}.lotNumber`] = item.itemBilling[0]?.firstMaterialLotNumber ?? ""
          acc[`${item.id}.materialBrand`] = item.itemBilling[0]?.firstMaterialTraceabilityId ?? ""
        });
        return (acc);
      }, {}),
      ...(order.products ?? []).reduce((acc, product) => {
        (product?.implantItem ?? []).forEach((item) => {
          acc[`${item.id}.lotNumber`] = item.itemBilling[0]?.firstMaterialLotNumber ?? ""
          acc[`${item.id}.materialBrand`] = item.itemBilling[0]?.firstMaterialTraceabilityId ?? ""
        });
        return (acc);
      }, {})
    }
  });

  const updateItemById = async(values, item, modelTypenames) => {
    const selectedMaterialTraceability = (allMaterialTraceability?.getAllMaterialTraceability ?? []).find((materialTraceability) => {
      return (
        materialTraceability.id === values[item.id].materialBrand
      );
    });
    switch (modelTypenames) {
      case ModelTypenames.AnatomyItem:
          await updateItemBilling({
            variables: {
              data: {
                firstMaterialLotNumber: {
                  set: values[item.id].lotNumber
                },
                firstMaterialReference: selectedMaterialTraceability.firstMaterialReference
                ? {
                  set: selectedMaterialTraceability.firstMaterialReference,
                } : undefined,
                firstMaterialBrand: selectedMaterialTraceability.firstMaterialBrand
                ? {
                  set: selectedMaterialTraceability.firstMaterialBrand,
                } : undefined,
                firstMaterialApprovalReference: selectedMaterialTraceability.firstMaterialApprovalReference
                ? {
                  set: selectedMaterialTraceability.firstMaterialApprovalReference,
                } : undefined,
                firstMaterialTraceability: selectedMaterialTraceability.id
                ? {
                  connect: {
                    id: selectedMaterialTraceability.id
                  }
                } : undefined,
                firstMaterialCountry: selectedMaterialTraceability.firstMaterialCountry
                ? {
                  set: selectedMaterialTraceability.firstMaterialCountry
                } : undefined
              },
              where: {
                id: item.itemBilling[0].id
              }
            }
          });    
        break;
      case ModelTypenames.RemovableItem:
          await updateItemBilling({
            variables: {
              data: {
                firstMaterialLotNumber: {
                  set: values[item.id].lotNumber
                },
                firstMaterialReference: selectedMaterialTraceability.firstMaterialReference
                ? {
                  set: selectedMaterialTraceability.firstMaterialReference,
                } : undefined,
                firstMaterialBrand: selectedMaterialTraceability.firstMaterialBrand
                ? {
                  set: selectedMaterialTraceability.firstMaterialBrand,
                } : undefined,
                firstMaterialApprovalReference: selectedMaterialTraceability.firstMaterialApprovalReference
                ? {
                  set: selectedMaterialTraceability.firstMaterialApprovalReference,
                } : undefined,
                firstMaterialTraceability: selectedMaterialTraceability.id
                ? {
                  connect: {
                    id: selectedMaterialTraceability.id
                  }
                } : undefined,
              },
              where: {
                id: item.itemBilling[0].id
              }
            }
          });
        break;
      case ModelTypenames.ImplantItem:
        await updateItemBilling({
          variables: {
            data: {
              firstMaterialLotNumber: {
                set: values[item.id].lotNumber
              },
              firstMaterialReference: selectedMaterialTraceability.firstMaterialReference
              ? {
                set: selectedMaterialTraceability.firstMaterialReference,
              } : undefined,
              firstMaterialBrand: selectedMaterialTraceability.firstMaterialBrand
              ? {
                set: selectedMaterialTraceability.firstMaterialBrand,
              } : undefined,
              firstMaterialApprovalReference: selectedMaterialTraceability.firstMaterialApprovalReference
              ? {
                set: selectedMaterialTraceability.firstMaterialApprovalReference,
              } : undefined,
              firstMaterialTraceability: selectedMaterialTraceability.id
              ? {
                connect: {
                  id: selectedMaterialTraceability.id
                }
              } : undefined,
            },
            where: {
              id: item.itemBilling[0].id
            }
          }
        });
        break;
    }
  }

  const onSubmit = async(values) => {
    try {
      (order?.products ?? []).forEach(async(product) => {
        Promise.all(
          (product?.anatomyItem ?? []).map(async(item) => {
            await updateItemById(values, item, ModelTypenames.AnatomyItem)
          })
        )
        Promise.all(
          (product?.removableItem ?? []).map(async(item) => {
            await updateItemById(values, item, ModelTypenames.RemovableItem)
          })
        )
        Promise.all(
          (product?.implantItem ?? []).map(async(item) => {
            await updateItemById(values, item, ModelTypenames.ImplantItem)
          })
        )
      });
      refetch();
      notifications.show({
        title: "Correctly saved",
        color: "green",
        message: "",
      });
      closeModal();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to save lot number or material brand",
        color: "red",
        message: "",
      });
    }
  };
  
  return (
    <>
      {(order?.products ?? []).map((product) => (
        <div key={product.id}>
          {(product?.anatomyItem ?? []).map((item) => (
            <UpdateItemMaterialBrandAndLotNumberComponent
              key={item.id}
              item={item}
              product={product}
              errors={errors}
              control={control}
              allMaterialTraceability={allMaterialTraceability?.getAllMaterialTraceability ?? []}
            />
          ))}
          {(product?.removableItem ?? []).map((item) => (
            <UpdateItemMaterialBrandAndLotNumberComponent
              key={item.id}
              item={item}
              product={product}
              errors={errors}
              control={control}
              allMaterialTraceability={allMaterialTraceability?.getAllMaterialTraceability ?? []}
            />
          ))}
          {(product?.implantItem ?? []).map((item) => (
            <UpdateItemMaterialBrandAndLotNumberComponent
              key={item.id}
              item={item}
              product={product}
              errors={errors}
              control={control}
              allMaterialTraceability={allMaterialTraceability?.getAllMaterialTraceability ?? []}
            />
          ))}
        </div>
      ))}
      <Space h="md"/>
      <div style={{ textAlign: 'center' }}>
        <Button style={{ backgroundColor: STYLE.primary }} onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </div>
    </>
  );
};

export default UpdateMaterialAndLotModal;
