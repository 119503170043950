import { UserType } from "@jasper/shared"
import { Image } from "./carousel.utils";

export const getLetterOfUser = (fristName : string, userType : UserType) => {
    return userType === UserType.JASPER ? 'J' : fristName.slice(0,1);
}


export interface messages {
    createdAt: Date,
    updatedAt: Date,
    userGroup: {
      name: string,
      groupType: UserType,
      id: string
    },
    message: string,
    orderId: string,
    files: Image[] | undefined
  }