import { createContext } from 'react';
import axios from 'axios';
import { getApiUrl } from '/@/config';
import Cookies from 'js-cookie';
import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";

import {
  getDomain,
} from "@jasper/shared";

export const UserContext = createContext();

export const UseAuth = () => {

  const me = async() => {
    try {
      const usr = await axios.get(`${getApiUrl()}/users/me`, { withCredentials: true });
      if (!usr || !usr.data){
        return (null);
      }
      clearCookies();
      const domain = getDomain(import.meta.env.MODE);
      Cookies.set("Authentication", usr.data.accessToken, { domain: domain });
      Cookies.set("email", usr.data.email, { domain: domain });
      Cookies.set("userGroupId", usr.data.userGroupId, { domain: domain });
      Cookies.set("id", usr.data.id, { domain: domain });
      Cookies.set("role", usr.data.role, { domain: domain });
      Cookies.set("type", usr.data.type, { domain: domain });
      if (usr.data.refreshToken.split("Refresh=").length > 1){
        Cookies.set("Refresh", usr.data.refreshToken.split("Refresh=")[1].split(';')[0], { domain: domain });
      }
      return (usr.data);
    }
    catch (e){
      console.error(e);
      return (null);
    }
  };

  const login = async (data: { email: string, password: string }): Promise<boolean> => {
    try {
      const req = await axios.post(`${getApiUrl()}/auth/login`, data);
      Sentry.setUser({
        id: req.data.id,
        email: data.email,
      });

      clearCookies();
      const domain = getDomain(import.meta.env.MODE);
      Cookies.set("email", data.email, { domain: domain });
      Cookies.set("userGroupId", req.data.userGroupId, { domain: domain });
      Cookies.set("id", req.data.id, { domain: domain });
      Cookies.set("role", req.data.role, { domain: domain });
      Cookies.set("type", req.data.type, { domain: domain });
      Cookies.set("Authentication", req.data.accessToken, { domain: domain });
      if (req.data.refreshToken.split("Refresh=").length > 1){
        Cookies.set("Refresh", req.data.refreshToken.split("Refresh=")[1].split(';')[0], { domain: domain });
      }
      return (req.data);
    } catch (e) {
      notifications.show({
        title: "Wrong email or password",
        color: "red",
        message: "",
      });
      console.error(e);
      return (false);
    }
  };

  const getUser = () => {
    return {
      id: Cookies.get('id'),
      email: Cookies.get('email'),
      role: Cookies.get('role'),
      type: Cookies.get('type'),
      userGroupId: Cookies.get('userGroupId'),
    };
  };

  const logOut = async() => {
    await axios.get(`${getApiUrl()}/auth/logout`, { withCredentials: true });
    clearCookies();
  }

  const clearCookies = () => {
    const domain = getDomain(import.meta.env.MODE);
    Cookies.remove("id", { domain: domain });
    Cookies.remove("email", { domain: domain });
    Cookies.remove("role", { domain: domain });
    Cookies.remove("type", { domain: domain });
    Cookies.remove("Refresh", { domain: domain });
    Cookies.remove("Authentication", { domain: domain });
    Cookies.remove('userGroupId', { domain: domain });
  };

  return {
    user: getUser(),
    login,
    me,
    logOut,
  };
};
