import { useState, useContext, useEffect } from 'react'
import { Box } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Input,
  SecureInput,
  Heading,
  Button,
} from '/@/composants/';
import { UseAuth } from '/@/contexts';

import {
  OrderStatus,
  UserType,
  isUserStaffNotAdmin,
} from "@jasper/shared";

const Login = () => {

  const [searchParams] = useSearchParams();
  const { login, me } = UseAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);

  const { register, handleSubmit, formState: { errors } } = useForm<{email: string, password: string}>({
    defaultValues: {
      email: "",
      password: "",
    }
  })

  const redirectAfterLogin = (usr) => {
    if (isUserStaffNotAdmin(usr)){
      navigate("/groups");
      return;
    }
    const next = searchParams.get('next') || `/home?status=${OrderStatus.CONFIRMED}&page=0`
    navigate(next);
  }

  const submit = async (value: { email: string, password: string }) => {
    const usr = await login(value);
    if (usr){
      redirectAfterLogin(usr);
    }
  };

  const isUserAlreadyConnected = async() => {
    const usr = await me();
    if (usr){
      redirectAfterLogin(usr);
    }
    setLoading(false);
  }
  useEffect(() => {
    isUserAlreadyConnected();
  }, [false]);

  if (loading){
    return (null)
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      <Box>
        <Heading value="Welcome to Jasper Lab portal" center />
        <Input
          label="Email"
          placeholder="Your email address"
          name="email"
          register={register}
          errors={errors}
          required
        />
        <SecureInput
          label="Password"
          placeholder="Your password"
          name="password"
          register={register}
          errors={errors}
          required
        />
        <Button value="Submit" onClick={handleSubmit(submit)} testid="submit-button-login"/>
      </Box>
    </div>
  )
}

export default Login;
