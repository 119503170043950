import React, { useRef, useEffect,useState } from 'react';
import './messageInput.css';
import { ActionIcon, Textarea } from '@mantine/core';
import { IconSend, IconPaperclip } from '@tabler/icons-react';
import { Image } from '../utils/carousel.utils';
import { FileType } from '@jasper/shared';
import { PreviewPhoto } from './PreviewPhoto'
import { UploadedFile } from './UploadedFile';

export interface MessageInputProps {
  setMessageToSend: (newValue: string) => void;
  send: () => void;
  uploadFile: () => void;
  files: File[];
  setFiles: (newValue: File[]) => void;
  renderingFiles: Image[];
  setRenderingFiles: (newValue: Image[]) => void;
  setShowModal: (value: boolean) => void;
  temp: boolean;
  setTemp: (value: boolean) => void;
}

export const MessageInput = ({
  ...props
}: MessageInputProps) => {
  const input = useRef(null)
  const fileInput = useRef(null);
  const [tempImages, setTempImages] = useState<Image[]>([])
  const [tempFiles, setTempFiles] = useState<Image[]>([])

  const getImageTypeFromFile = async (files: File[]): Promise<Image[]> => {
    const filePromises = files.map((file, index) => {
      return new Promise<Image>((resolve, reject) => {
        if (file.type.match('image.*')) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            resolve({
              key: file.name,
              id: index.toString(),
              path: e.target.result as string,
              fileType: FileType.Chat_File
            });
          };
          reader.onerror = (error) => reject(error);
        }
        else {
          resolve({
            key: file.name,
            id: index.toString(),
            path: null,
            fileType: FileType.Chat_File
          });
        }
      });
    });
    return Promise.all(filePromises);
  };

  const send = () => {
    if ((input.current && input.current.value !== '') || props.files.length !== 0) {
      props.send();
      input.current.value = ''
      props.setMessageToSend('')
      setTempImages([])
      setTempFiles([])
      props.setFiles([])
    }
  }

  const handlePictures = async(e) => {
    const newFiles = e.target ? e.target.files : e
    const fileList: File[] = [...props.files, ...newFiles];
    props.setFiles(fileList)
    const images = await getImageTypeFromFile(fileList);
    setTempImages(images.filter((file)=> file.path !== null))
    setTempFiles(images.filter((file)=> file.path === null))
  }

  const dropHandler = (ev) => {
      ev.preventDefault();
  
    if (ev?.dataTransfer?.items) {
      const newFiles = [];
      [...ev.dataTransfer.items].forEach((item, i) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          newFiles.push(file)
        }
      });
      handlePictures(newFiles)
    }
  }

  useEffect(()=>{
    props.setFiles([])
  }, [props.renderingFiles.length === 0 && props.renderingFiles.length > 0])

  return (
    <div className='input-box'
    onDrop={(event) => dropHandler(event)}
    >
      <PreviewPhoto
        setShowModal={props.setShowModal}
        setRenderingFiles={(files) => props.setRenderingFiles(files)}
        setTempFiles={(files) => setTempImages(files)}
        setFiles={(files) => props.setFiles(files)}
        tempFiles={tempImages ?? []}
        input={fileInput}
      />
      <UploadedFile 
        setTempFiles={(files) => setTempFiles(files)}
        tempFiles={tempFiles ?? []}
        input={fileInput}
        isTempFile={true} 
        setFiles={(files) => props.setFiles(files)}
      />
      <div className='input-container'>
        <ActionIcon
          variant="filled"
          color="indigo"
          aria-label="Settings"
          className='icon-button'
          onClick={() => { fileInput.current.click()}}
        >
          <IconPaperclip
            style={{ width: '100%', height: '100%' }}
            stroke={1.5} />
        </ActionIcon>
        <Textarea
          ref={input}
          placeholder='Tape your text here'
          style={{ display: 'table-cell', width: 'inherit' }}
          onChange={(event) => props.setMessageToSend(event.target.value)}
          autosize
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              send()
            }
          }}
        />
        <div style={{ display: 'flex', gap: '5px' }}>
          <input
            ref={fileInput}
            onChange={async (e) => {
              handlePictures(e)
            }}
            style={{display:'none'}} 
            multiple 
            type="file" 
            accept="image/*,application/dicom,model/stl,application/zip,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <ActionIcon
            variant="filled"
            color="indigo"
            aria-label="Settings"
            className='icon-button'
            onClick={() => { send() }}
          >
            <IconSend
              style={{ width: '100%', height: '100%' }}
              stroke={1.5} />
          </ActionIcon>
        </div>
      </div>
    </div>
  );
};

