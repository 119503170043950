import React, { useState, useRef, useEffect } from 'react';
import './carousel.css';
import {Image} from '../utils/carousel.utils'
import { IconTrash } from '@tabler/icons-react';

import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight
} from "@tabler/icons-react";

export interface CarouselProps {
  imageList: Image[],
  hasDeleteOption?: boolean,
  setImageToDelete?: (file: Image) => void,
  isSavedFile?: boolean
}

export const Carousel = ({
  ...props
}: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const translateValue = useRef(0);

  const slideChange = (nextSlideValue : number, nextTranslateValue: number) => {
    translateValue.current = nextTranslateValue;
    let elems = document.getElementsByClassName('carousel-figure');
    [...elems].forEach((elem) => {
      elem.setAttribute('style', `transform: translateX(${translateValue.current}%)`);
    })
    setCurrentSlide(nextSlideValue);
  }
  useEffect(()=>{
    slideChange(0,0)
  },[props.imageList])
  return (
    <div
      style={{
        width: '100%',
        margin: 'auto',
      }}
    >
      <div
        style={{
          backgroundColor: 'black',
          display: 'flex',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {
          currentSlide > 0 && (
            <button
              id='left-button'
              onClick={() => {
                slideChange(currentSlide - 1,translateValue.current + 100);
              }}
            >
              <IconArrowNarrowLeft
                size={38}
                color={'white'}
                style={{
                  position: 'absolute',
                  left: '0',
                  top: 'calc(50% - 19px)',
                  color: 'white',
                  cursor: 'pointer'
                }}
              />
            </button>
          )
        }
        {props.imageList.map((img) =>
          <figure
            className='carousel-figure'
            key={img.id}
          >
            <img style={{ margin: 'auto', maxWidth: '100%' }} src={img.path} />
          </figure>
        )}
        {
          currentSlide < props.imageList.length - 1 && (
            <button
              id='right-button'
              disabled={currentSlide === props.imageList.length - 1}
              onClick={() => {
                slideChange(currentSlide + 1,translateValue.current - 100);
              }}
            >
              <IconArrowNarrowRight
                size={38}
                color={'white'}
                style={{
                  position: 'absolute',
                  right: '0',
                  top: 'calc(50% - 19px)',
                  cursor: 'pointer'
                }}
              />
            </button>
          )
        }
      </div>
      { props.imageList.length > 0 && currentSlide < props.imageList.length &&
        <p style={{textAlign:'center', margin:'0'}}>
          {props.isSavedFile ? props.imageList[currentSlide].key.substring(37) : props.imageList[currentSlide].key} 
          {props.hasDeleteOption && typeof props.setImageToDelete !== "undefined" &&
            <span>
              <IconTrash
                style={{
                  verticalAlign: 'text-bottom',
                  height: '18px',
                  width: 'auto',
                  marginLeft: '5px',
                  cursor: 'pointer'
                }}  
                color='red'
                onClick={() => {
                  props.setImageToDelete(props.imageList[currentSlide])
                  if(currentSlide === props.imageList.length -1 && currentSlide > 0) {
                    slideChange(currentSlide - 1,translateValue.current + 100)
                  }
                }}
              />
            </span>
          }
        </p>
      }
    </div>
  );
}
