import React, {useRef, useEffect, useState} from 'react';
import './chat.css';
import { messages } from '../utils/chat.utils';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import { Badge, Modal} from '@mantine/core';
import { Image } from '../utils/carousel.utils';
import { Carousel } from '../Carousel/Carousel';


export interface ChatProps {
  messages: messages[]
  currentUserGroup: string
  setMessageToSend: (newValue: string) => void;
  send: () => void,
  filesToSend: File[]
  setFilesToSend: (files : File[]) => void
}

export const Chat = ({
  ...props
}: ChatProps) => {
  const container = useRef(null)
  const modal = useRef(null)
  const [showModal,setShowModal] = useState(false)
  const [renderingFiles, setRenderingFiles] = useState<Image[]>([]);
  const [isRenderingSavedFiles, setIsRenderingSavedFiles] = useState<boolean>(false);
  const [imageToDelete, setImageToDelete] = useState<Image | null>(null)
  const [temp, setTemp] = useState<boolean>(false)

  const deletingTemporaryFiles = (file: Image) => {
    const indexFile = props.filesToSend.findIndex(f => file.key === f.name);
    const indexRenderingFile = renderingFiles.findIndex(f => file.id === f.id);
    if (indexFile > -1) {
      props.filesToSend.splice(indexFile, 1)
    }
    if (indexRenderingFile > -1) {
      renderingFiles.splice(indexRenderingFile, 1)
    }
    setImageToDelete(null)
  }

  useEffect(() => {
    container.current.scrollTop = container.current.scrollHeight;
  },[])

  useEffect(() => {
    container.current.scrollTop = container.current.scrollHeight;
  },[props.messages])

  useEffect(() => {
    if (imageToDelete) {
      deletingTemporaryFiles(imageToDelete)
    }
  }, [imageToDelete])

  useEffect(() => {
    setShowModal(false)
  },[renderingFiles.length === 0 && showModal])

  return <div className='box-component'>
    <Modal 
      ref={modal}
      opened={showModal} 
      onClose={() => setShowModal(false)}
      closeOnClickOutside={true}
    >
      { renderingFiles && 
        <Carousel
          imageList={renderingFiles}
          hasDeleteOption={isRenderingSavedFiles ? false :true}
          setImageToDelete={setImageToDelete}
          isSavedFile={false}
        />
      }
    </Modal>
    <div className='messages-container' ref={container}>
      {
        props.messages && props.messages.map((message, index) =>
          <Message
            setShowModal={(value) => {setShowModal(value); setIsRenderingSavedFiles(true)}}
            setRenderingFiles={(value) => setRenderingFiles(value)} 
            key={`message-group-${index}`}
            message={message}
            position={message.userGroup.id === props.currentUserGroup ? 'right' : 'left'}
            previousDate={
              props.messages.length > 1 &&
                index > 0 ?
                props.messages[index - 1].createdAt : null
            }
            previousMessageUser={
              props.messages.length > 1 &&
                index > 0 ?
                props.messages[index - 1].userGroup.id : null
            }
          />
        )
      }
      {
        props.messages.length === 0 && (
          <Badge className='date'>Today</Badge>
        )
      }
    </div>
    <MessageInput
      setShowModal={(value) => {setShowModal(value); setIsRenderingSavedFiles(false)}}
      send={props.send}
      setMessageToSend={props.setMessageToSend}
      setFiles={(files) => props.setFilesToSend(files)} 
      setRenderingFiles={(value) => setRenderingFiles(value)} 
      renderingFiles={renderingFiles} 
      files={props.filesToSend} 
      temp={temp}
      setTemp={setTemp}
      uploadFile={() => {}}/>
  </div>;
}
