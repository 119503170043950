import { FileType } from '../types/enums';
import { File } from '../types/interfaces';

export const get3OXZFile = (allFiles : File[]) : File[] => {
  return allFiles
      .filter((file) =>
          file?.name?.toLowerCase() === FileType.ORDER_FILE.replace("_", "").toLowerCase() && file?.key?.toLowerCase().toLowerCase().includes('3oxz')
      );
}

export const getTraceabilitiesFile = (allFiles : File[]) : File[] => 
    allFiles
        .filter((file) => 
            file?.name?.toUpperCase() === FileType.BILLING_DENTIST_TRACEABILITY ||
            file?.name?.toUpperCase() === FileType.BILLING_PATIENT_TRACEABILITY
        )

export const getImagesFile = (allFiles : File[]) : File[] => 
    allFiles
        .filter((file) => {
            const fileNameSplit = file?.key?.toLocaleLowerCase().split('.');
            return ['jpg','jpeg','png', 'dcm', 'stl'].includes(fileNameSplit[fileNameSplit.length - 1]) ||
            file.fileType === FileType.CLIENT_FILE
        })

export const isUUID = (id: string) => {
    const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
    return (id.match(regex))
}