import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {FileWithPath} from "@mantine/dropzone";
import axios from "axios";
import {
  CreateFileDocument,
  DeleteFileDocument,
  GetFilesOfOrderDocument,
  GET_FILE_DOWNLOAD_URL,
  GET_FILE_UPLOAD_URL,
} from "../gql/files";
import { FileType } from "../types/enums";


export const useFiles = (orderId: string) => {
  const {data: filesOfOrder, refetch: refetchFilesOfOrder} = useQuery(GetFilesOfOrderDocument, {
    variables: {
      where: {
        orderId: {equals: orderId},
      },
    },
    fetchPolicy: "network-only",
  });
  const [createFile] = useMutation(CreateFileDocument, {
    refetchQueries: [GetFilesOfOrderDocument],
  });
  const [deleteFile] = useMutation(DeleteFileDocument, {
    refetchQueries: [GetFilesOfOrderDocument],
  });
  const [getPresignedDownload] = useLazyQuery(
    GET_FILE_DOWNLOAD_URL,
  );
  const [getPresignedPost] = useLazyQuery(GET_FILE_UPLOAD_URL);

  const getPresignedDownloadUrl = async (key: string) => {
    const res = await getPresignedDownload({
      variables: {
        key: key,
      },
    });
    window.open(res?.data?.getPresignedFileDownloadUrl, "_blank");
  };

  const getFileUrl = async (key: string) => {
    const res = await getPresignedDownload({
      variables: {
        key: key,
      },
    });
    return res?.data?.getPresignedFileDownloadUrl;
  };

  const deleteOneFile = async (id: string, key: string) => {
    deleteFile({
      variables: {
        key: key,
        where: {
          id: id,
        },
      },
    });
    // }).then(res => {
    //   getFilesOfOrder({
    //     variables: {
    //       where: {
    //         orderId: {equals: order.id},
    //       },
    //     },
    //   });
    // });
  };

  // functions to upload to s3
  const uploadFileToS3 = async (files: FileWithPath[], fileType?: FileType): Promise<any[]> => {
    if (!files) return null;
    const filesWithKey: any[] = await Promise.all(
      files.map(async (file, index) => {
        const getPresignedPostData = await getPresignedPost({
          variables: {
            fileName: file.name,
          },
        });
        const presignedPost = JSON.parse(
          getPresignedPostData?.data?.getPresignedFileUploadUrl ?? "",
        );
        const uploadFormData = new FormData();
        Object.keys(presignedPost.fields).forEach(key => {
          uploadFormData.append(key, presignedPost.fields[key]);
        });
        uploadFormData.append("file", file);
        const res = await axios({
          method: "POST",
          url: presignedPost.url,
          data: uploadFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.status === 201 && presignedPost.fields.key) {
          // Add to the filesWithKey array
          const newFileData = {
            fileType: FileType.CHAT_FILE,
            key: presignedPost.fields.key ?? null,
            order: {
              connect: {
                id: orderId,
              },
            },
          };
  
          if (!fileType || fileType !== FileType.CHAT_FILE) {
            await createFile({
              variables: {
                args: {
                  key: presignedPost.fields.key ?? null,
                  order: {
                    connect: {
                      id: orderId,
                    },
                  },
                  fileType: fileType !== FileType.ORDER_FILE ? fileType: 'OrderFile',
                },
              },
            });
          }
  
          return newFileData;
        }
  
        return null;
      })
    );
  
    return filesWithKey.filter(file => file !== null); // Filter out null values if any
  };
  

  const dataURLtoFile = (dataurl: string, filename: string): File => {
    const [meta, base64Data] = dataurl.split(",");
    const mimeMatch = meta.match(/:(.*?);/);

    if (!mimeMatch) {
      throw new Error("Invalid data URL");
    }

    const mime = mimeMatch[1];
    const binaryString = atob(base64Data);
    const length = binaryString.length;
    const u8arr = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      u8arr[i] = binaryString.charCodeAt(i);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return {
    getPresignedDownloadUrl,
    deleteOneFile,
    uploadFileToS3,
    filesOfOrder,
    dataURLtoFile,
    getFileUrl,
    refetchFilesOfOrder,
  };
};
