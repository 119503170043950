import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Collapse, Group, Text } from '@mantine/core';
import {
  GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
} from '/@/gql/';
import { getApiUrl } from '/@/config';
import { io } from "socket.io-client";

import { UseAuth } from '/@/contexts';
import Logo from "/@/assets/logo.svg";
import {
  STYLE,
  OrderStatus,
  isUserProvider,
  isUserStaff,
  isUserAdmin,
} from '@jasper/shared';

import packageJson from "../../package.json";
import { useDisclosure } from '@mantine/hooks';
import { IconSquareRoundedArrowUpFilled, IconSquareRoundedArrowDownFilled } from '@tabler/icons-react'

const NavLink = (props: {
  title: string,
  onClick: () => any,
  active?: boolean,
  notificationNumber?:number
}) => {
  return (
    <div
      style={{
        cursor: "pointer",
        color: props.active ? STYLE.primary : "#050027",
        fontWeight: 650,
        textAlign: "left",
        paddingBottom: "0.5rem",
      }}
      onClick={props.onClick}
    >
      {props.title}
      {props.notificationNumber && 
        <span 
          style={{
            position: 'absolute',
            width:'20px',
            height:'20px',
            borderRadius: '50px',
            backgroundColor:'#F24034',
            marginLeft: '10px',
            fontSize: 'x-small',
            textAlign: 'center',
            lineHeight: '20px',
            color: 'white'
          }}
        >
          { props.notificationNumber}
        </span>
      }
    </div>
  );
};

const Navbar = () => {
  const { logOut, user } = UseAuth();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);

  const [url, setUrl] = useState<string>("");
  const [searchParams] = useSearchParams();

  const { data: dataMsg, refetch } = useQuery(
    GET_LAST_UNREAD_MESSAGES_OF_EACH_ORDER,
    {
      variables: {
        args: {
          skip: 0,
          take: 20
        }
      },
    }
  );

  const logOutFromLabs = async() => {
    await logOut();
    navigate('/');
  }

  const nextUrl = (urlToNavigate: string) => {
    navigate(urlToNavigate);
    setUrl(urlToNavigate);
  }

  useEffect(() => {}, [url])

  useEffect(() => {
    const socket = io(getApiUrl(),
    {
      query: {
        type: 'notification'
      },
    });
    
    socket.connect()

    socket.on('notification', () => {
      refetch()
    })
    return () => {
      socket.disconnect()
      socket.off('connect')
      socket.off('disconnect')
      socket.off('notification')
    }
  }, [])

  return (
    <div
      style={{
        textAlign: "center"
      }}
    >
      <div
        style={{
          paddingBottom: "2rem"
        }}
      >
        <img src={Logo}/>
        <div style={{color: "lightgrey"}}>
        <Text
          size="xs"
          style={{textAlign: "center"}}
        >
          v{packageJson.version}
        </Text>
        </div>
      </div>
      {(isUserAdmin(user) || isUserProvider(user)) &&
        <div style={{marginBottom: '20px'}}>
          <NavLink
            title={'NOTIFICATIONS'}
            onClick={() => nextUrl(`/chats?page=0`)}
            active={window.location.pathname.search("/chats") >= 0}
            notificationNumber={
              (dataMsg?.getLastUnreadOfEachOrder?.notification ?? [])
                .filter((chat) => chat.read === false && chat.userGroup.id !== user.userGroupId).length > 0 ?
                  (dataMsg?.getLastUnreadOfEachOrder?.notification ?? [])
                    .filter((chat) => chat.read === false && chat.userGroup.id !== user.userGroupId).length
                  : undefined }
          />
        </div>
      }
      {(isUserAdmin(user)) &&
        <>
          <NavLink
            title="INBOX/NEW CASES"
            onClick={() => nextUrl(`/home?status=${OrderStatus.CONFIRMED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.CONFIRMED}
          />
          <hr/>
          <NavLink
            title="ALL"
            onClick={() => nextUrl('/home?status=ALL&page=0')}
            active={searchParams.get('status') === "ALL"}
          />
          <NavLink
            title={OrderStatus.ON_HOLD}
            onClick={() => nextUrl(`/home?status=${OrderStatus.ON_HOLD}&page=0`)}
            active={searchParams.get('status') === OrderStatus.ON_HOLD}
          />
          <NavLink
            title="TO SHIP"
            onClick={() => nextUrl(`/home?status=TO_SHIP&page=0`)}
            active={searchParams.get('status') === "TO_SHIP"}
          />
          <Group mb={5}>
            <div
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left'
              }}
              onClick={toggle}
            >
              OTHER
              <span style={{position: 'absolute', right: '20px'}}>
                { opened ? 
                  <IconSquareRoundedArrowUpFilled/>
                  : <IconSquareRoundedArrowDownFilled/>
                }
              </span>
            </div>
          </Group>
          <Collapse in={opened}>
            <NavLink
              title={OrderStatus.WAITING_FOR_PRODUCTION}
              onClick={() => nextUrl(`/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`)}
              active={searchParams.get('status') === OrderStatus.WAITING_FOR_PRODUCTION}
            />
            <NavLink
              title={OrderStatus.PRODUCING}
              onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING}&page=0`)}
              active={searchParams.get('status') === OrderStatus.PRODUCING}
            />
            <NavLink
              title={isUserProvider(user) ? "SHIPPED TO FRANCE" : OrderStatus.PRODUCING_IN_TRANSIT}
              onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING_IN_TRANSIT}&page=0`)}
              active={searchParams.get('status') === OrderStatus.PRODUCING_IN_TRANSIT}
            />
            <NavLink
              title={OrderStatus.SHIPPED}
              onClick={() => nextUrl(`/home?status=${OrderStatus.SHIPPED}&page=0`)}
              active={searchParams.get('status') === OrderStatus.SHIPPED}
            />
            <NavLink
              title={OrderStatus.DELIVERED}
              onClick={() => nextUrl(`/home?status=${OrderStatus.DELIVERED}&page=0`)}
              active={searchParams.get('status') === OrderStatus.DELIVERED}
            />
            <NavLink
              title="ARCHIVED"
              onClick={() => nextUrl(`/home?status=ARCHIVED&page=0`)}
              active={searchParams.get('status') === "ARCHIVED"}
            />
          </Collapse>
        </>
      }
      {isUserProvider(user) &&
        <>
          <NavLink
            title={OrderStatus.WAITING_FOR_PRODUCTION}
            onClick={() => nextUrl(`/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`)}
            active={searchParams.get('status') === OrderStatus.WAITING_FOR_PRODUCTION}
          />
          <NavLink
            title={OrderStatus.PRODUCING}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING}
          />
          <NavLink
            title={isUserProvider(user) ? "SHIPPED TO FRANCE" : OrderStatus.PRODUCING_IN_TRANSIT}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING_IN_TRANSIT}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING_IN_TRANSIT}
          />
          <NavLink
            title={OrderStatus.DELIVERED}
            onClick={() => nextUrl(`/home?status=${OrderStatus.DELIVERED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.DELIVERED}
          />
          <NavLink
            title={OrderStatus.ON_HOLD}
            onClick={() => nextUrl(`/home?status=${OrderStatus.ON_HOLD}&page=0`)}
            active={searchParams.get('status') === OrderStatus.ON_HOLD}
          />
          <NavLink
            title="TO SHIP"
            onClick={() => nextUrl(`/home?status=TO_SHIP&page=0`)}
            active={searchParams.get('status') === "TO_SHIP"}
          />
        </>
      }
      <div
        style={{
          position: "absolute",
          bottom: "1rem"
        }}
      >
        {isUserStaff(user) &&
          <>
            {(isUserAdmin(user)) &&
              <>
                <NavLink
                  title={OrderStatus.DRAFT}
                  onClick={() => nextUrl(`/home?status=${OrderStatus.DRAFT}&page=0`)}
                  active={searchParams.get('status') === OrderStatus.DRAFT}
                />
                <hr style={{ width: "11.5vw" }}/>
              </>
            }
            <NavLink
              title="GROUPS"
              onClick={() => nextUrl(`/groups`)}
              active={window.location.pathname.search("/groups") >= 0}
            />
            <NavLink
              title="USERS"
              onClick={() => nextUrl(`/users`)}
              active={window.location.pathname.search("/users") >= 0}
            />
            <hr style={{ width: "11.5vw" }}/>
          </>
        }
        <NavLink
          title="Log out"
          onClick={logOutFromLabs}
        />
      </div>
    </div>
  );
};

export default Navbar;
