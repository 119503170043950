import React from "react";
import {
  Text,
  TextInput,
  PasswordInput,
} from '@mantine/core';
import { Controller } from 'react-hook-form';
import { MantineProvider } from '@mantine/core';

const TextInputRef = ({ name, label, errors, control, required, secure, disabled, placeholder }) => {
  return (
    <MantineProvider>
      {secure ? (
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ?? true,
          }}
          render={({field}) => (
            <PasswordInput
              label={label}
              disabled={disabled ?? false}
              placeholder={placeholder ?? ""}
              {...field}
            />
          )}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ?? true,
          }}
          render={({field}) => (
            <TextInput
              label={label}
              disabled={disabled ?? false}
              placeholder={placeholder ?? ""}
              {...field}
            />
          )}
        />
      )}
      {errors?.[name]?.type === "required" && (
        <Text
          size="sm"
          style={{color: "red"}}
        >
          This field is required
        </Text>
      )}
    </MantineProvider>
  )
}

export default TextInputRef;
